/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module('app')
        .constant('secureTrading',
        {
            siteReference: 'test_aifspartnerships56849'
        });

}