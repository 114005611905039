/// <reference path="../../typings/references.d.ts" />

module PartnershipsModule {

    var endpoints = {
        // I'm still not completely sure what should we put here
        'https://pp-cuev-func-t.azurewebsites.net/api/v1/admin': 'https://acismis.onmicrosoft.com/culturaleventsservicesdev',
        '//pp-cuev-func-t.azurewebsites.net/api/v1/admin':'https://acismis.onmicrosoft.com/culturaleventsservicesdev'
    }

    angular
        .module('app')
        .constant('adalAuthenticationServiceConfig', {
            tenant: 'aifs.co.uk',
            clientId: 'c4d7015a-28be-4fb5-8a48-c2375232b2bf',
            cacheLocation: 'localStorage',
            redirectUri: 'https://test.aifsppculture.com/admin/sign-in-success', //TODO:: use uri name instead of hardcoding
            endpoints: endpoints // in case we will be using CORS
        });

}